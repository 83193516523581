import lodashCompact from 'lodash-es/compact';

import { AssetType, BusClass } from '~/apiClient';
import { Locale } from '~/i18n/i18nConfig';

type FormatAlpRouteOptionalParams = (optionalParams: OptionalParams) => string[] | '';

type FormatAlpRouteParams = {
    assetType: AssetType;
    assetClass?: BusClass;
    brandUrlKey?: string;
    modelUrlKey?: string;
};

type FormatAlpRoute = (params: FormatAlpRouteParams) => {
    name: string;
    params: { localizedAssetType: string; optionalParams: '' | string[] };
};

type ChangeAlpLocale = (locale: Locale) => void;

export interface UseAlpRouteUtilsReturn {
    formatAlpRouteOptionalParams: FormatAlpRouteOptionalParams;
    formatAlpRoute: FormatAlpRoute;
    changeAlpLocale: ChangeAlpLocale;
}

export const useAlpRouteUtils = (): UseAlpRouteUtilsReturn => {
    const route = useRoute();
    const { parsedRouteParams, localizedAssetTypes, localizedClasses } = useAlpRouteParamsParser();

    const formatAlpRouteOptionalParams: FormatAlpRouteOptionalParams = optionalParams => {
        const usefulOrderedParams = lodashCompact(optionalParamsOrder.map(param => optionalParams[param]));
        const paramsMappingSegment = optionalParamsOrder
            .filter(param => optionalParams[param])
            .map(param => optionalParamsLetter[param])
            .join('');

        return usefulOrderedParams.length ? [...usefulOrderedParams, paramsMappingSegment] : '';
    };

    const formatAlpRoute: FormatAlpRoute = ({ assetType, assetClass, brandUrlKey, modelUrlKey }) => {
        const getRoute = (optionalParams: '' | string[]): ReturnType<FormatAlpRoute> => ({
            name: 'auctions-localizedAssetType-optionalParams',
            params: { localizedAssetType: localizedAssetTypes.value[assetType], optionalParams },
        });

        if (!assetType && !brandUrlKey && !modelUrlKey) return getRoute('');

        const optionalParams: OptionalParams = {
            localizedClass: assetClass && localizedClasses.value[assetType][assetClass],
            brandUrlKey,
            modelUrlKey,
        };

        return getRoute(formatAlpRouteOptionalParams(optionalParams));
    };

    const changeAlpLocale: ChangeAlpLocale = locale => {
        const assetType = parsedRouteParams.value.assetType!;
        const localizedAssetType = localizedParams[locale].assetType[assetType];
        const classEnum = parsedRouteParams.value.assetClass ? parsedRouteParams.value.assetClass : undefined;
        const localizedClass = classEnum && localizedParams[locale].class[assetType][classEnum];
        const { brandUrlKey, modelUrlKey } = parsedRouteParams.value;
        const localePath = useLocalePath();

        navigateTo(
            localePath(
                {
                    params: { localizedAssetType, optionalParams: formatAlpRouteOptionalParams({ localizedClass, brandUrlKey, modelUrlKey }) },
                    query: route.query,
                },
                locale
            )
        );
    };

    return {
        formatAlpRouteOptionalParams,
        formatAlpRoute,
        changeAlpLocale,
    };
};
